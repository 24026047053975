import React, { FC } from "react";
import { graphql, PageProps } from "gatsby";
import { MainLayout } from "@/layouts/MainLayout";
import Helmet from "react-helmet";
import { SEO } from "@/components/basic/SEO";
import { Link } from "@/components/basic/Link";
import { SiteFooterNewsletterButton } from "@/components/basic/SiteFooterNewsletterButton";
import { SiteFooterWrapper } from "@/components/basic/SiteFooterWrapper";
import { BlogPostBox } from "@/components/BlogPostBox";
import { Pagination } from "@/components/Pagination";
import { BlogHeroSection } from "@/components/BlogHeroSection";

export interface PageContextProps {
  pageNumber: number;
  humanPageNumber: number;
  skip: number;
  limit: number;
  numberOfPages: number;
  previousPagePath: string;
  nextPagePath: string;
  popularTags: string[];
}

const BlogTemplate: FC<PageProps<GatsbyTypes.Query, PageContextProps>> = ({
  data: { allContentfulNewsPost: blogPostsContent },
  pageContext,
}) => (
  <MainLayout>
    <Helmet>
      <body className="ltr blog" />
    </Helmet>
    <SEO
      title="Blog"
      description="ANIXE is a leading travel technology company. Here you will find all our news,
        media and event details. Our latest articles appear first. ANIXE’S Resfinity Platform is a
        technologically advanced, online retail and distribution platform. It is built to provide intuitive
        shopping experience for airlines’ customers and tour operators. Resfinity is a cloud hosted solution
        available as Software as a Service which means it is highly scalable and provides leading industry uptime.
        The platform is constantly developed and often overtaking market trends, yet you get the opportunity to
        implement your own ideas."
    />
    <BlogHeroSection popularTags={pageContext.popularTags}>
      <h1 className="color-primary">Blog</h1>
      <p>Latest articles about our partners, products and solutions.</p>
    </BlogHeroSection>
    <section className="l-wrapper color-bg-negative clearfix">
      <div className="clearfix l-with-dbl-vertical-gutters-mobile">
        {blogPostsContent.edges.map(({ node }) => (
          <div
            key={node.title}
            className="d-block l-6-cols-tablet l-4-cols-desktop clearfix l-dbl-push-bottom"
          >
            <BlogPostBox
              url={`/blog/${node.url}`}
              date={node.date}
              title={node.title}
              lead={node.lead}
              coverImage={node.coverImage.file.url}
              imageAlt={node.coverImage.title}
            />
          </div>
        ))}
      </div>
    </section>
    <div className="l-wrapper">
      <Pagination
        previousPagePath={pageContext.previousPagePath}
        nextPagePath={pageContext.nextPagePath}
      />
    </div>
    <SiteFooterWrapper>
      <SiteFooterNewsletterButton />
      <Link
        classname="button button-quaternary-negative-dark button-estimate-project"
        url="/estimate-project"
        text="estimate project"
      />
    </SiteFooterWrapper>
  </MainLayout>
);

export default BlogTemplate;

export const query = graphql`
  query($skip: Int!, $limit: Int!) {
    allContentfulNewsPost(
      skip: $skip
      limit: $limit
      filter: { url: { ne: null } }
      sort: { fields: [date], order: DESC }
    ) {
      edges {
        node {
          date(formatString: "DD MMM YYYY", locale: "en-US")
          title
          url
          lead
          coverImage {
            title
            file {
              url
            }
          }
        }
      }
    }
  }
`;
