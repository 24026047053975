import React, { FC } from "react";
import { Link } from "gatsby";

interface PaginationProps {
  previousPagePath?: string;
  nextPagePath?: string;
}

export const Pagination: FC<PaginationProps> = ({
  previousPagePath,
  nextPagePath,
}) => (
  <div className="d-flex-box flex-box-space-between ">
    {previousPagePath && (
      <Link className="push-left color-primary-alt" to={previousPagePath}>
        <span>←</span> Previous page
      </Link>
    )}
    {nextPagePath && (
      <Link className="push-right color-primary-alt" to={nextPagePath}>
        Next page <span>→</span>
      </Link>
    )}
  </div>
);
